window.onscroll = function () {
    stickyMenu();
};
    var navbar = document.getElementById("header_sticky");
    var sticky = navbar.offsetTop;

    function stickyMenu() {
        if (window.scrollY >= sticky) {
            navbar.classList.add("sticky");
        } else {
            navbar.classList.remove("sticky");
        }
}